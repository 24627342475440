<template>
  <div>
    <div class="lg:flex lg:space-x-10">
      <div class="w-full space-y-7">
        <div class="card p-3">
          <div class="flex justify-between flex-col-reverse lg:flex-row">
            <nav class="responsive-nav pl-2 is_ligh -mb-0.5 border-transparent h-12">
              <ul>
                <li><a href="#" v-on:click="trashed='WITHOUT'">Tất cả<span>{{ allDocumentsCount }}</span></a>
                </li>
                <li><a href="#" v-on:click="trashed='ONLY'">Bị xóa<span>{{deletedDocumentsCount }}</span></a>
                </li>
              </ul>
            </nav>
            
            <router-link :to="{name: 'AdminDocumentAdd'}" class="whitespace-nowrap text-white hover:text-white hover:bg-green-700 bg-green-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd"></path></svg> Thêm mới
            </router-link>
          </div>

          <form class="mt-4" v-on:submit.prevent="processBatchDocument">
            <div class="flex space-x-4">
              <input v-model="q" placeholder="Tìm kiếm" type="text" class="form-control shadow-none with-border"/>
              <select v-model="action" class="btn-group bootstrap-select shadow-none with-border">
                <option value="">-- Thao tác --</option>
                <option value="delete">Xóa</option>
                <option value="restore">Khôi phục</option>
              </select>
              <button :disabled="!selectedDocumentIds.length || !action" type="submit"
                      class="hover:bg-gray-300 bg-gray-200 rounded-md whitespace-nowrap px-4">Thực hiện
              </button>
            </div>
          </form>

          <div class="lg:mt-8 mt-2">
            <table class="w-full border-collapse">
              <thead>
              <tr class="border-b border-slate-200">
                <th class="flex align-items-center">
                  <div class="checkbox">
                    <input type="checkbox" id="checkbox" v-model="isCheckedAll"/>
                    <label for="checkbox"><span class="checkbox-icon"></span></label>
                  </div>
                </th>
                <th class="py-2 pr-2">Tiêu đề</th>
                <th class="py-2 pr-2">Đóng góp</th>
                <th class="py-2 pr-2">Ngày đăng</th>
                <th class="py-2 pr-2">Thao tác</th>
              </tr>
              </thead>
              <tbody class="divide-y" v-if="documents.data && documents.data.length">
              <document-item v-on:deleteDocument="deleteDocument" unique="documents" :document="document" v-on:handleCheckedDocument="handleCheckedDocument"
                         v-for="(document, index) in documents.data" :key="'documents' + index"/>
              </tbody>
            </table>

            <div class="mt-4">
              <v-pagination
                  class="justify-content-center"
                  v-if="documents.paginatorInfo.lastPage > 1"
                  v-model="documents.paginatorInfo.currentPage"
                  :pages="documents.paginatorInfo.lastPage"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="loadAllDocuments"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../../core/services/api.service";
import DocumentItem from "./DocumentItem";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import {changePageTitle, canManageDocument} from "../../../core/services/utils.service";

export default {
  name: "AdminDocumentList",
  components: {DocumentItem, VPagination},
  data() {
    return {
      q: "",
      action: "",
      isCheckedAll: false,
      trashed: "WITHOUT",
      documents: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      allDocumentsCount: 0,
      deletedDocumentsCount: 0,
      selectedDocumentIds: [],
      currentDocument: null,
      reason: ""
    }
  },
  methods: {
    loadAllDocuments() {
      let where = {
        AND: []
      }

      if (this.q) {
        where.AND.push({column: "title", value: this.q + "%", operator: "LIKE"});
      }

      let query = `query($page: Int, $where: WhereConditions, $trashed: Trashed) {
        documents(trashed: $trashed, first:10, where: $where, page: $page, orderBy: [{column: "created_at", order:DESC}]) {
          data {
            id
            title
            slug
            created_at
            thumbnail {
              url
            }
            uploader {
              id
              username
            }
            file {
              audio_url
              video_url
              type
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {trashed: this.trashed, where: where, page: this.documents.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.documents) {
              this.documents = data.data.documents;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadAllDocumentsCount() {
      let query = `query {
        documents(first:1, page: 1) {
          paginatorInfo {
            total
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.documents && data.data.documents.paginatorInfo) {
              this.allDocumentsCount = data.data.documents.paginatorInfo.total;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadDeletedDocumentsCount() {
      let query = `query {
        documents(trashed: ONLY, first:1, page: 1) {
          paginatorInfo {
            total
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.documents && data.data.documents.paginatorInfo) {
              this.deletedDocumentsCount = data.data.documents.paginatorInfo.total;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    handleCheckedDocument(document, isChecked) {
      this.documents.data.map((item) => {
        if (item.id === document.id) {
          item.is_checked = isChecked;
          return false;
        }
      })
    },
    processBatchDocument() {
      if (confirm("Bạn có chắc muốn thực hiện thao tác này?")) {
        let query = `mutation($ids: [ID!], $action: String!) {
          processBatchDocument(ids: $ids, action: $action)
        }`;

        ApiService.graphql(query, {ids: this.selectedDocumentIds, action: this.action})
            .then(({data}) => {
              if (data.data && data.data.processBatchDocument) {
                this.reRender();
              }
            })
            .catch((response) => {
              console.log(response);
            });
      }
    },
    deleteDocument(document) {
      if (confirm("Bạn có chắc muốn xóa tư liệu này?")) {
        let query = `mutation($id: ID!) {
          deleteDocument(id: $id) {
            id
          }
        }`;

        ApiService.graphql(query, {id: document.id})
            .then(({data}) => {
              if (data.data && data.data.deleteDocument) {
                this.reRender();
                this.$toast.success("Xóa tư liệu thành công");
              }
            })
            .catch((response) => {
              console.log(response);
              this.$toast.success("Xóa tư liệu không thành công: " + response.message);
            });
      }
    },
    reRender() {
      this.isCheckedAll = false;
      this.selectedDocumentIds = [];
      this.loadAllDocuments();
      this.loadAllDocumentsCount();
      this.loadDeletedDocumentsCount();
    }
  },
  mounted() {
    if (!canManageDocument) {
      this.$router.push({name: "Home"});
      return;
    }
    this.reRender();
    changePageTitle("Quản lý tư liệu");
  },
  watch: {
    q: {
      handler() {
        this.loadAllDocuments();
      }
    },
    isCheckedAll: {
      handler(newVal) {
        this.documents.data.map((document) => {
          document.is_checked = newVal;
        });
      }
    },
    "documents.data": {
      handler(newVal) {
        this.selectedDocumentIds = [];
        newVal.map((document) => {
          if (document.is_checked) {
            this.selectedDocumentIds.push(document.id);
          }
        });
      },
      deep: true
    },
    trashed: {
      handler() {
        this.documents.paginatorInfo.currentPage = 1;
        this.reRender();
      },
    }
  },
  computed: {
    canManageDocument() {
      return canManageDocument();
    }
  }
}
</script>
