<template>
  <tr class="">
    <td class="flex align-items-center">
      <div class="checkbox">
        <input type="checkbox" :id="'checkbox' + unique + document.id" v-model="isChecked"/>
        <label :for="'checkbox' + unique + document.id"><span class="checkbox-icon"></span></label>
      </div>
    </td>
    <td class="p-2 font-semibold">
      <router-link :to="{name: 'AdminDocumentEdit', params: {id: document.id}}">
        {{ document.title }}
      </router-link>
    </td>
    <td class="p-2 whitespace-nowrap">
      <router-link :to="{name: 'UserDetail', params: {id: document.uploader.id}}" v-if="document.uploader">
        {{ document.uploader.username }}
      </router-link>
    </td>
    <td class="p-2 whitespace-nowrap">
      {{ timeago(document.created_at) }}
    </td>
    <td class="p-2 whitespace-nowrap">
      <router-link class="mr-3" :to="{name: 'DocumentDetail', params: {id: document.id, slug: document.slug}}">
        Xem
      </router-link>
      <router-link :to="{name: 'AdminDocumentEdit', params: {id: document.id}}" class="mr-3">
        Sửa
      </router-link>
      <a href="javascript:void(0)" v-on:click="deleteDocument(document)">Xóa</a>
    </td>
  </tr>
</template>

<script>

import {
  timeago
} from "../../../core/services/utils.service";

export default {
  name: "AdminDocumentItem",
  props: {
    document: Object,
    unique: String
  },
  data() {
    return {
      isChecked: false
    }
  },
  methods: {
    timeago(x) {
      return timeago(x);
    },
    deleteDocument(document) {
      this.$emit("deleteDocument", document);
    }
  },
  created() {
    this.isChecked = this.document.is_checked;
  },
  watch: {
    "document.is_checked": {
      handler(newVal) {
        this.isChecked = newVal;
      },
      deep: true
    },
    isChecked: {
      handler(newVal) {
        this.$emit("handleCheckedDocument", this.document, newVal);
      }
    },
  }
}
</script>
